import { makeStyles } from '@material-ui/core/styles';
import { COLOR_WHITE } from '../../theme/constants';

export const usePriceCalculatorStyles = makeStyles(() => ({
  paper: { minWidth: '800px' },
  box: {
    marginTop: '-50px',
    margin: '0 auto',
    marginBottom: '1px',
    maxWidth: '1220px',
    height: 'auto',
    paddingBottom: '5px',
  },
  boxbeschreibung: {
    maxWidth: '1220px',
    margin: '0 auto',
    marginTop: '20px',
    padding: '5px 10px 10px 10px',
    backgroundColor: COLOR_WHITE,
    minHeight: '220px',
    color: '#000038',
  },

  imagesbox: {
    marginTop: '5px',
    margin: '0 auto',
    maxWidth: '1220px',
    marginBottom: '50px',
    paddingBottom: '20px',
    backgroundColor: COLOR_WHITE,
  },

  imagesbox2: {
    margin: '0 auto',
    maxWidth: '1220px',

    marginBottom: '50px',
    padding: '20px 0px 20px 10px ',
    backgroundColor: COLOR_WHITE,
  },
  textbox: {
    display: 'flex',
    margin: '0 auto',
    fontSize: '1.3rem',
    fontWeight: 600,
    alignItems: 'center',
  },
  slider: {
    display: 'flex',
    margin: '0 auto',
    padding: '50px 30px 0px 30px',
    backgroundColor: COLOR_WHITE,
    maxWidth: '1000px',
  },

  stepper: {
    padding: '5px 50px 0px 50px',
    marginBottom: '0px',
    marginTop: '20px',
    margin: '0 auto',
    maxWidth: '1040px',
  },
  stepper2: {
    padding: '5px 50px 0px 50px',
    marginBottom: '1px',
  },
  label: {
    fontSize: '1.1rem',
    fontWeight: 600,
    marginTop: '5px',
    color: '#080c2d',
  },
  labelContent: {
    color: '#545372',
  },
  ueberschrift: {
    display: 'flex',
    margin: '0 auto',
    fontSize: '0.95rem',
    fontWeight: 600,
    justifyContent: 'center',
    color: '#000038',
    padding: '10px 10px 0px 10px ',
    letterSpacing: '0.9px',
    maxWidth: '1200px',
    textAlign: 'Center',
  },
  ergebnis: {
    display: 'flex',
    margin: '0 auto',
    fontSize: '1rem',
    fontWeight: 400,
    justifyContent: 'left',
    color: '#000038',
    padding: '0px 0px 0px 0px',
  },

  boxcontrolle0: {
    margin: '5px 5px 0px 5px',
    textAlign: 'center',
    marginTop: '30px',
  },

  boxconthover: {
    margin: '5px 2px 0px 2px',
    borderStyle: 'solid',
    borderColor: '#ea5b27',
    borderWidth: '0.11rem',
    backgroundColor: '#FAFAFA',
    borderRadius: '3px',
    '&:hover': {
      backgroundColor: '#efefef',
      borderColor: '#4caf50',
      borderWidth: '0.11rem',
    },
  },
  boxconthoverleft: {
    margin: '5px 2px 0px 10px',
    borderStyle: 'solid',
    borderColor: '#ea5b27',
    borderWidth: '0.11rem',
    backgroundColor: '#FAFAFA',
    borderRadius: '3px',
    '&:hover': {
      backgroundColor: '#efefef',
      borderColor: '#4caf50',
      borderWidth: '0.11rem',
    },
  },

  boxconthoverright: {
    margin: '5px 10px 0px 2px',
    borderStyle: 'solid',
    borderColor: '#ea5b27',
    borderWidth: '0.11rem',
    backgroundColor: '#FAFAFA',
    borderRadius: '3px',
    '&:hover': {
      backgroundColor: '#efefef',
      borderColor: '#4caf50',
      borderWidth: '0.11rem',
    },
  },

  boxcontrolle: {
    // margin: '5px 5px 0px 5px',
    // backgroundColor: '#FAFAFA',
    textAlign: 'center',
    height: '50px',
    marginTop: '3px',
  },
  boxcontrolle1: {
    // margin: '0px 5px 5px 5px',
    // backgroundColor: '#FAFAFA',
    textAlign: 'center',
    marginTop: '0px',
  },
  controlle0: {
    fontSize: '1.10rem',
    fontWeight: 400,
    color: '#080c2d',
    letterSpacing: '0.9px',
  },
  controlle1: {
    fontSize: '0.88rem',
    fontWeight: 400,
    color: '#545372',
  },
  controlle2: {
    fontSize: '0.99rem',
    fontWeight: 600,
    color: '#545372',
  },
  sendBox: {
    display: 'grid',
    justifyContent: 'right',
    padding: '20px 10px 0px 10px ',
  },
  dialogbox: {
    width: '1000px',
  },
  dialogcontent: {
    fontSize: '0.87rem',
    fontWeight: 400,
    color: '#666666',
  },
  dialogcontentdurchschnitt: {
    fontSize: '0.95rem',
    fontWeight: 400,
    color: '#666666',
    marginTop: '5px',
  },
  dialogcontentgreen: {
    fontSize: '0.8rem',
    fontWeight: 600,
    marginTop: '0px',
    color: '#098c0e',
  },
  dialogcontenterrorMessage: {
    fontSize: '0.8rem',
    fontWeight: 600,
    marginTop: '5px',
    color: '#ff0000',
  },
  dialogcontentboldhead: {
    fontSize: '0.87rem',
    fontWeight: 600,
    marginTop: '0px',
    color: '#000038',
  },
  dialogcontentbold: {
    fontSize: '0.95rem',
    fontWeight: 600,
    marginTop: '20px',
    color: '#000038',
  },
  dialogcontentergebnisbold: {
    fontSize: '0.87rem',
    fontWeight: 600,
    marginTop: '0px',
    color: '#666666',
  },
  dialogcontentinfo: {
    fontSize: '0.87rem',
    fontWeight: 400,
    marginTop: '1px',
    color: '#098c0e',
  },

  button: {
    whiteSpace: 'nowrap',
    letterSpacing: '1.9px',
    backgroundColor: '#ea5b27',
    border: 1,
    borderRadius: 4,
    color: 'white',
    height: 34,
    width: 'auto',
    padding: '0px 20px 0px 20px',

    '&:hover': {
      backgroundColor: '#ff652d',
      borderColor: '#0062cc',
      boxShadow: 'none',
      textDecoration: 'none',
      whiteSpace: 'nowrap',
    },
    '&:link': {
      whiteSpace: 'nowrap',
    },
    '&:visited': {
      whiteSpace: 'nowrap',
    },
    '&:a': {
      whiteSpace: 'nowrap',
    },
  },

  cell_longHeader: {
    textAlign: 'left',
    color: '#000038',
    fontSize: '0.87rem',
    width: 400,
    backgroundColor: '#efefef',
    '& .MuiTableCell-sizeSmall': {
      padding: '6px 0px 6px 16px', // <-- arbitrary value
    },
  },

  cell_shortHeader: {
    textAlign: 'right',
    color: '#000038',
    fontSize: '0.87rem',
    backgroundColor: '#efefef',
  },

  cell_long: {
    textAlign: 'left',
    color: '#666666',
    fontSize: '0.85rem',
    width: 400,
    '& .MuiTableCell-sizeSmall': {
      padding: '6px 0px 6px 16px', // <-- arbitrary value
    },
  },

  cell_short: {
    textAlign: 'right',
    color: '#666666',
    fontSize: '0.85rem',
    width: 150,
  },

  cell_longHeaderFive: {
    textAlign: 'left',
    color: '#000038',
    fontSize: '0.87rem',
    width: 250,
    backgroundColor: '#efefef',
  },

  cell_shortHeaderFive: {
    textAlign: 'right',
    color: '#000038',
    fontSize: '0.87rem',
    backgroundColor: '#efefef',
  },

  cell_longFive: {
    textAlign: 'left',
    color: '#666666',
    fontSize: '0.85rem',
    width: 250,
  },

  cell_shortFive: {
    textAlign: 'right',
    color: '#666666',
    fontSize: '0.85rem',
    width: 150,
  },

  customTable: {
    '& .MuiTableCell-sizeSmall': {
      padding: '3px 10px 3px 10px', // <-- arbitrary value
    },
  },

  contentBoxInstruction: {
    maxWidth: 1000,
  },
  siteTitle: {
    fontSize: '2.6rem',
    fontWeight: '600',
    color: COLOR_WHITE,
    marginTop: '50px',
    animation: 'textwelle 0.6s',
  },
  siteTitleTwo: {
    fontSize: '2.6rem',
    fontWeight: '600',
    color: COLOR_WHITE,
    marginTop: '5px',
    animation: 'textwelle 0.6s',
  },
  siteTitleContent: {
    fontSize: '1.3rem',
    fontWeight: '400',
    color: COLOR_WHITE,
    marginLeft: '0px',
    animation: 'textwelle 0.5s',
  },
  headheadingSite: {
    color: '#080c2d',
    fontSize: '2.8rem',
    fontWeight: 900,
    textAlign: 'center',
    letterSpacing: '4px',
  },
  headheadingContent: {
    color: '#080c2d',
    fontSize: '1.4rem',
    fontWeight: 400,
    textAlign: 'center',
    letterSpacing: '0.5px',
  },
  boxImagesHead: {
    minHeight: '300px',
  },
  headImagesTextPosition: {
    display: 'inline-flex',
    maxWidth: '1440px',
    padding: '4em 1em 0em 1em',
  },
}));
