/* eslint-disable eqeqeq */
/* eslint-disable max-len */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import {
  Box,
  Button,
  Divider,
  Grid,
  MobileStepper,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';
import { withStyles, useTheme } from '@material-ui/core/styles';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { usePriceCalculatorStyles } from './styles';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    minWidth: '1100px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const LinkText = styled(Link)`
  display: inline-block;
  z-index: -2;
  text-decoration: none;
  color: #ea5e2a;

  &:hover {
    color: #ea5e2a;
    text-decoration: none;
  }
  &:after {
    opacity: 0;
    text-decoration: none;
  }
  &:hover&:after {
    opacity: 0;
    text-decoration: none;
  }
`;

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const marks1 = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 125,
    label: '125',
  },
  {
    value: 250,
    label: '250',
  },
  {
    value: 375,
    label: '375',
  },
  {
    value: 500,
    label: '500',
  },
  {
    value: 625,
    label: '625',
  },
  {
    value: 750,
    label: '750',
  },
  {
    value: 875,
    label: '875',
  },
  {
    value: 1000,
    label: '1000',
  },
];
const marks2 = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 30,
    label: '30',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 60,
    label: '60',
  },
  {
    value: 70,
    label: '70',
  },
  {
    value: 80,
    label: '80',
  },
  {
    value: 90,
    label: '90',
  },
  {
    value: 100,
    label: '100',
  },
];
const marks3 = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 75,
    label: '75',
  },
  {
    value: 100,
    label: '100',
  },
  {
    value: 125,
    label: '125',
  },
  {
    value: 150,
    label: '150',
  },
  {
    value: 175,
    label: '175',
  },
  {
    value: 200,
    label: '200',
  },
  {
    value: 225,
    label: '225',
  },
  {
    value: 250,
    label: '250',
  },
];
const marks4 = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 30,
    label: '30',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 60,
    label: '60',
  },
  {
    value: 70,
    label: '70',
  },
  {
    value: 80,
    label: '80',
  },
  {
    value: 90,
    label: '90',
  },
  {
    value: 100,
    label: '100',
  },
];
const marks5 = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 15,
    label: '15',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 30,
    label: '30',
  },
  {
    value: 35,
    label: '35',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 45,
    label: '45',
  },
  {
    value: 50,
    label: '50',
  },
];
const marks6 = [
  {
    value: 0,
    label: '0 min',
  },
  {
    value: 15,
    label: '15 min',
  },
  {
    value: 30,
    label: '30 min',
  },
  {
    value: 45,
    label: '45 min',
  },

  {
    value: 60,
    label: '60 min',
  },
];
const marks7 = [
  {
    value: 0,
    label: '0,00 €',
  },
  {
    value: 15,
    label: '15,00 €',
  },
  {
    value: 30,
    label: '30,00 €',
  },
  {
    value: 45,
    label: '45,00 €',
  },
  {
    value: 60,
    label: '60,00 €',
  },
  {
    value: 75,
    label: '75,00 €',
  },
  {
    value: 90,
    label: '90,00 €',
  },
];

// function valueLabelFormat(value) {
//   return `${value}`;
// }

const PricesCalculator = () => {
  const { backgroundImageCallCenter } = useStaticQuery(
    graphql`
      query {
        backgroundImageCallCenter: file(relativePath: { eq: "employeework.png" }) {
          childImageSharp {
            gatsbyImageData(width: 2000, quality: 50, webpOptions: { quality: 70 })
          }
        }
      }
    `,
  );

  const pluginImage = getImage(backgroundImageCallCenter);
  const { t } = useTranslation();
  const tutorialSteps = [
    {
      labeltop: t('trans.calculatorStepHeadOne'),
      label: t('trans.calculatorStepSliderOne'),
      lowvalue: 0,
      highestvalue: 1000,
      marks: marks1,
      stepdefault: 0,
      step: 1,
      contentleftone: t('trans.calculatorStepContentOneOne'),
      contentlefttwo: t('trans.calculatorStepContentOneTwo'),
      contentleftthree: t('trans.calculatorStepContentOneThree'),
      contentleftfour: t('trans.calculatorStepContentOneFour'),
      contentleftfive: t('trans.calculatorStepContentOneFive'),
      name: 'employee',
    },
    {
      labeltop: t('trans.calculatorStepHeadTwo'),
      label: t('trans.calculatorStepSliderTwo'),
      lowvalue: 0,
      highestvalue: 100,
      marks: marks2,
      stepdefault: 0,
      step: 1,
      contentleftone: t('trans.calculatorStepContentTwoOne'),
      contentlefttwo: t('trans.calculatorStepContentTwoTwo'),
      contentleftthree: t('trans.calculatorStepContentTwoThree'),
      contentleftfour: t('trans.calculatorStepContentTwoFour'),
      contentleftfive: t('trans.calculatorStepContentTwoFive'),
      name: 'visitor',
      boxBackGround: '#000038',
    },
    {
      labeltop: t('trans.calculatorStepHeadThree'),
      label: t('trans.calculatorStepSliderThree'),
      lowvalue: 0,
      highestvalue: 250,
      marks: marks3,
      stepdefault: 0,
      step: 1,
      contentleftone: t('trans.calculatorStepContentThreeOne'),
      contentlefttwo: t('trans.calculatorStepContentThreeTwo'),
      contentleftthree: t('trans.calculatorStepContentThreeThree'),
      contentleftfour: t('trans.calculatorStepContentThreeFour'),
      contentleftfive: t('trans.calculatorStepContentThreeFive'),
      name: 'externalEmployees',
    },
    {
      labeltop: t('trans.calculatorStepHeadFour'),
      label: t('trans.calculatorStepSliderFour'),
      lowvalue: 0,
      highestvalue: 100,
      marks: marks4,
      stepdefault: 0,
      step: 1,
      contentleftone: t('trans.calculatorStepContentFourOne'),
      contentlefttwo: t('trans.calculatorStepContentFourTwo'),
      contentleftthree: t('trans.calculatorStepContentFourThree'),
      contentleftfour: t('trans.calculatorStepContentFourFour'),
      contentleftfive: t('trans.calculatorStepContentFourFive'),
      name: 'instruction',
    },
    {
      labeltop: t('trans.calculatorStepHeadFive'),
      label: t('trans.calculatorStepSliderFive'),
      lowvalue: 0,
      highestvalue: 50,
      marks: marks5,
      stepdefault: 0,
      step: 1,
      contentleftone: t('trans.calculatorStepContentFiveOne'),
      contentlefttwo: t('trans.calculatorStepContentFiveTwo'),
      contentleftthree: t('trans.calculatorStepContentFiveThree'),
      contentleftfour: t('trans.calculatorStepContentFiveFour'),
      contentleftfive: t('trans.calculatorStepContentFiveFive'),
      name: 'averageInstruction',
    },
    {
      labeltop: t('trans.calculatorStepHeadSix'),
      label: t('trans.calculatorStepSliderSix'),
      lowvalue: 0,
      highestvalue: 60,
      marks: marks6,
      stepdefault: 0,
      step: 5,
      contentleftone: t('trans.calculatorStepContentSixOne'),
      contentlefttwo: t('trans.calculatorStepContentSixTwo'),
      contentleftthree: t('trans.calculatorStepContentSixThree'),
      contentleftfour: t('trans.calculatorStepContentSixFour'),
      contentleftfive: t('trans.calculatorStepContentSixFive'),
      name: 'duration',
    },
    {
      labeltop: t('trans.calculatorStepHeadSeven'),
      label: t('trans.calculatorStepSliderSeven'),
      lowvalue: 0,
      highestvalue: 90,
      marks: marks7,
      stepdefault: 30,
      step: 0.5,
      contentleftone: t('trans.calculatorStepContentSevenOne'),
      contentlefttwo: t('trans.calculatorStepContentSevenTwo'),
      contentleftthree: t('trans.calculatorStepContentSevenThree'),
      contentleftfour: t('trans.calculatorStepContentSevenFour'),
      contentleftfive: t('trans.calculatorStepContentSevenFive'),
      name: 'costs',
    },
  ];
  const theme = useTheme();
  const classes = usePriceCalculatorStyles();

  const [open, setOpen] = React.useState(false);

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const buttonForNext = React.useRef(null);
  const buttonForBack = React.useRef(null);

  const step = tutorialSteps[activeStep];
  const [values, setValues] = React.useState({
    employee: tutorialSteps[0].stepdefault,
    visitor: tutorialSteps[1].stepdefault,
    externalEmployees: tutorialSteps[2].stepdefault,
    instruction: tutorialSteps[3].stepdefault,
    averageInstruction: tutorialSteps[4].stepdefault,
    duration: tutorialSteps[5].stepdefault,
    costs: tutorialSteps[6].stepdefault,
  });

  const { employee, visitor, externalEmployees, instruction, averageInstruction, duration, costs } = values;

  const handleChange = (event, newValue, key) => {
    const newValues = {
      ...values,
      [key]: newValue,
    };
    setValues(newValues);
  };

  // const totalVisitor = visitor + externalEmployees;

  const employeePrices99 = 1.0;
  const employeePrices499 = 0.95;
  const employeePrices500 = 0.9;

  const instructionPrices5 = 5.0;
  const instructionPrices25 = 3.5;
  const instructionPrices26 = 1.5;

  // const visitor50 = 1.00;

  let errorMessage = '';

  if (costs == '') {
    errorMessage = t('trans.calculatorErrorMessageCosts');
  }
  if (duration == '') {
    errorMessage = t('trans.calculatorErrorMessageDuration');
  }
  if (averageInstruction == '') {
    errorMessage = t('trans.calculatorErrorMessageAverageInstruction');
  }
  if (instruction == '') {
    errorMessage = t('trans.calculatorErrorMessageInstruction');
  }
  if (employee == '') {
    errorMessage = t('trans.calculatorErrorMessageEmployee');
  }

  let employee99 = 0;
  let employee499 = 0;
  let employee500 = 0;

  let instruction5 = 0;
  let instruction25 = 0;
  let instruction26 = 0;

  // let visitor50 = 0;

  const employeePrices99Euro = new Intl.NumberFormat(t('trans.IntlNumberFormat'), {
    style: 'currency',
    currency: 'EUR',
  }).format(employeePrices99);

  const employeePrices499Euro = new Intl.NumberFormat(t('trans.IntlNumberFormat'), {
    style: 'currency',
    currency: 'EUR',
  }).format(employeePrices499);

  const employeePrices500Euro = new Intl.NumberFormat(t('trans.IntlNumberFormat'), {
    style: 'currency',
    currency: 'EUR',
  }).format(employeePrices500);

  const instructionPrices5Euro = new Intl.NumberFormat(t('trans.IntlNumberFormat'), {
    style: 'currency',
    currency: 'EUR',
  }).format(instructionPrices5);

  const instructionPrices25Euro = new Intl.NumberFormat(t('trans.IntlNumberFormat'), {
    style: 'currency',
    currency: 'EUR',
  }).format(instructionPrices25);

  const instructionPrices26Euro = new Intl.NumberFormat(t('trans.IntlNumberFormat'), {
    style: 'currency',
    currency: 'EUR',
  }).format(instructionPrices26);

  // **********************  Kostenberechnung UMO  ***********************************
  // UMO Berechnung Benutzerkosten

  // if ((employee >= 1 && employee <= 50)) { visitor50 = employee; }

  if (employee >= 1 && employee <= 100) {
    employee99 = employee;
  }
  if (employee > 100 && employee <= 500) {
    employee99 = 100;
    employee499 = employee - 100;
  }
  if (employee > 500) {
    employee99 = 100;
    employee499 = 400;
    employee500 = employee - 500;
  }

  const employeePrices99Result = employee99 * employeePrices99;
  const employeePrices499Result = employee499 * employeePrices499;
  const employeePrices500Result = employee500 * employeePrices500;

  const employeeAll = employee99 + employee499 + employee500;
  const costAll = employeePrices99Result + employeePrices499Result + employeePrices500Result;

  const employeePrices99ResultEuro = new Intl.NumberFormat(t('trans.IntlNumberFormat'), {
    style: 'currency',
    currency: 'EUR',
  }).format(employeePrices99Result);

  const employeePrices499ResultEuro = new Intl.NumberFormat(t('trans.IntlNumberFormat'), {
    style: 'currency',
    currency: 'EUR',
  }).format(employeePrices499Result);

  const employeePrices500ResultEuro = new Intl.NumberFormat(t('trans.IntlNumberFormat'), {
    style: 'currency',
    currency: 'EUR',
  }).format(employeePrices500Result);

  const costAllEuro = new Intl.NumberFormat(t('trans.IntlNumberFormat'), {
    style: 'currency',
    currency: 'EUR',
  }).format(costAll);

  // *********************************************************************************
  // UMO Berechnung Unterweisungskosten

  if (instruction >= 1 && instruction <= 5) {
    instruction5 = instruction;
  }
  if (instruction >= 6 && instruction <= 25) {
    instruction5 = 5;
    instruction25 = instruction - 5;
  }
  if (instruction >= 26) {
    instruction5 = 5;
    instruction25 = 20;
    instruction26 = instruction - 25;
  }

  const instructionPrices5Result = instruction5 * instructionPrices5;
  const instructionPrices25Result = instruction25 * instructionPrices25;
  const instructionPrices26Result = instruction26 * instructionPrices26;

  const instructionAll = instruction5 + instruction25 + instruction26;
  const instructioncostAll = instructionPrices5Result + instructionPrices25Result + instructionPrices26Result;

  const instructionPrices5ResultEuro = new Intl.NumberFormat(t('trans.IntlNumberFormat'), {
    style: 'currency',
    currency: 'EUR',
  }).format(instructionPrices5Result);

  const instructionPrices25ResultEuro = new Intl.NumberFormat(t('trans.IntlNumberFormat'), {
    style: 'currency',
    currency: 'EUR',
  }).format(instructionPrices25Result);

  const instructionPrices26ResultEuro = new Intl.NumberFormat(t('trans.IntlNumberFormat'), {
    style: 'currency',
    currency: 'EUR',
  }).format(instructionPrices26Result);

  const instructioncostAllEuro = new Intl.NumberFormat(t('trans.IntlNumberFormat'), {
    style: 'currency',
    currency: 'EUR',
  }).format(instructioncostAll);

  // *********************************************************************************

  // ********************** Kostenberechnung Präsenzunterweisung  ********************

  // Berechnung Unterweisungskosten Präsenzunterweisung
  const valuesCosts = costs || 0;
  const costMinute = valuesCosts / 60;
  // const instructionMonthlyRound = instruction / 12;
  // const instructionMonthly = instructionMonthlyRound.toFixed(2);
  const instructionCost = (averageInstruction * (costMinute * duration) * employee) / 12 || 0;

  const instructionCostEuro = new Intl.NumberFormat(t('trans.IntlNumberFormat'), {
    style: 'currency',
    currency: 'EUR',
  }).format(instructionCost);

  // *********************************************************************************

  // Berechnung Unterweisungskosten Besucher
  const instructionCostVisitor = costMinute * duration * visitor || 0;
  // const instructionCostExternalEmployees = (costMinute * duration * externalEmployees) || 0;

  const gesamtinstructionCostVisitor = instructionCostVisitor;

  const gesamtinstructionCostVisitorEuro = new Intl.NumberFormat(t('trans.IntlNumberFormat'), {
    style: 'currency',
    currency: 'EUR',
  }).format(gesamtinstructionCostVisitor);

  // *********************************************************************************

  // Berechnung Unterweisungskosten  Betriebsfremde

  // const instructionCostVisitor = (costMinute * duration * visitor) || 0;
  const instructionCostExternalEmployees = costMinute * duration * externalEmployees || 0;

  const gesamtinstructionCostExternalEmployees = instructionCostExternalEmployees;

  const gesamtinstructionCostExternalEmployeesEuro = new Intl.NumberFormat(t('trans.IntlNumberFormat'), {
    style: 'currency',
    currency: 'EUR',
  }).format(gesamtinstructionCostExternalEmployees);

  // *********************************************************************************

  // Gesamtsumme Mitarbeiter + Besucher + Fremdarbeiter

  const gesamtCosts = instructionCost + instructionCostVisitor + instructionCostExternalEmployees || 0;

  const gesamtCostsEuro = new Intl.NumberFormat(t('trans.IntlNumberFormat'), {
    style: 'currency',
    currency: 'EUR',
  }).format(gesamtCosts);

  const costsEuro = new Intl.NumberFormat(t('trans.IntlNumberFormat'), {
    style: 'currency',
    currency: 'EUR',
  }).format(costs);

  // *********************************************************************************
  // Berechnung Ergebnis

  // Berechnung Unterweisungskosten Zeit mit umo
  // 1/3 Unterwiesungszeit mit UMO
  const durationUmo = duration / 3;

  const instructionCostUMO = (averageInstruction * (costMinute * durationUmo) * employee) / 12 || 0;

  const umoGesamt = instructioncostAll + costAll;

  const umoGesamtEuro = new Intl.NumberFormat(t('trans.IntlNumberFormat'), {
    style: 'currency',
    currency: 'EUR',
  }).format(umoGesamt);

  // Berechnung Ergebnis

  const summeNewUmo = instructioncostAll + costAll;
  const differenzCosts = gesamtCosts - summeNewUmo - instructionCostUMO || 0;
  // const monthPricesEmployee = summeUmo / employee || 0;
  // const monthPricesEmployeeGesamt = gesamtCosts / employee || 0;

  const procent = (differenzCosts * 100) / gesamtCosts || 0;
  const procentCosts = procent.toFixed(2);

  const newCostInstruction = instructionCostUMO + umoGesamt;

  const differenzCostsEuro = new Intl.NumberFormat(t('trans.IntlNumberFormat'), {
    style: 'currency',
    currency: 'EUR',
  }).format(differenzCosts);

  const newCostInstructionEuro = new Intl.NumberFormat(t('trans.IntlNumberFormat'), {
    style: 'currency',
    currency: 'EUR',
  }).format(newCostInstruction);

  // Berechnung Unterweisungskosten Zeit mit umo
  // 1/3 Unterwiesungszeit mit UMO

  const instructionCostUMOEuro = new Intl.NumberFormat(t('trans.IntlNumberFormat'), {
    style: 'currency',
    currency: 'EUR',
  }).format(instructionCostUMO);

  const handleClickOpen = () => {
    setOpen(true);

    const dataLayer = window.dataLayer || [];

    dataLayer.push({
      event: 'form_change_calculator_extended',
      calcCostsUmoEmployees: costAllEuro,
      calcCostsUmoInstructions: instructioncostAllEuro,
      calcCostsPresenceSum: gesamtCostsEuro,
    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  // *********************************************************************************

  return (
    <>
      <BgImage image={pluginImage} className="masthead2 ">
        <Grid container spacing={2} className={classes.headImagesTextPosition}>
          <Grid item lg={10} md={10} xs={12}>
            <Box className={classes.boxImagesHead}>
              <Typography className={classes.siteTitle}>{t('trans.calculatorImagesHeadOne')}</Typography>
              <Typography className={classes.siteTitleTwo}>{t('trans.calculatorImagesHeadTwo')}</Typography>

              <Box mt={1.5}>
                <Typography className={classes.siteTitleContent}>{t('trans.calculatorImagesContentOne')}</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </BgImage>

      <Box id="calculator" mt={9}>
        <Typography className={classes.headheadingSite}>{t('trans.calculatorHead')}</Typography>
      </Box>

      <Box className="masthead2 mastheadlightblue">
        <Grid container spacing={2} className={classes.headImagesTextPosition}>
          <Grid item lg={12} md={12} xs={12}>
            <Box className={classes.box}>
              <Typography className={classes.headheadingContent}>{t('trans.calculatorContentHead')}</Typography>
            </Box>
            <Box className={classes.boxbeschreibung}>
              <Typography className={classes.label}>{step.labeltop}</Typography>
              <Box mt={2}>
                <Typography className={classes.labelContent}>{step.contentleftone}</Typography>
                <Typography className={classes.labelContent}>{step.contentlefttwo}</Typography>
                <Typography className={classes.labelContent}>{step.contentleftthree}</Typography>
                <Typography className={classes.labelContent}>{step.contentleftfour}</Typography>
                <Typography className={classes.labelContent}>{step.contentleftfive}</Typography>
              </Box>
            </Box>
            <Box className={classes.imagesbox}>
              <Typography className={classes.ueberschrift}>{step.label}</Typography>
              <Box className={classes.slider}>
                <Slider
                  defaultValue={step.stepdefault}
                  // value={values[step.name] || ''}
                  value={values[step.name]}
                  min={step.lowvalue}
                  step={step.step}
                  max={step.highestvalue}
                  // getAriaValueText={valueLabelFormat}
                  // valueLabelFormat={valueLabelFormat}
                  onChange={(e, value) => handleChange(e, value, step.name)}
                  valueLabelDisplay="on"
                  aria-labelledby="non-linear-slider"
                  marks={step.marks}
                  style={{ color: '#ea5b27' }}
                />
              </Box>

              <Box className={classes.stepper}>
                <MobileStepper
                  variant="progress"
                  steps={7}
                  position="static"
                  activeStep={activeStep}
                  className={classes.root}
                  nextButton={
                    <Button buttonRef={buttonForNext} size="small" onClick={handleNext} disabled={activeStep === 6}>
                      {t('trans.calculatorNext')}
                      {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </Button>
                  }
                  backButton={
                    <Button buttonRef={buttonForBack} size="small" onClick={handleBack} disabled={activeStep === 0}>
                      {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                      {t('trans.calculatorBack')}
                    </Button>
                  }
                />
              </Box>
              <Grid item lg={12} md={12} xs={12}>
                <Box className={classes.sendBox}>
                  <Button
                    onClick={handleClickOpen}
                    className={classes.button}
                    variant="contained"
                    disabled={activeStep < 6}
                  >
                    {t('trans.calculatorResult')}
                  </Button>
                </Box>
              </Grid>
              <Box mt={2} px={2}>
                <Divider />
              </Box>

              <Grid container spacing={1}>
                <Grid item lg={12} md={12} xs={12}>
                  <Box className={classes.boxcontrolle0}>
                    <Typography className={classes.controlle0}>{t('trans.calculatorOverview')}</Typography>
                  </Box>
                </Grid>
                <Grid item lg={2} md={4} sm={4} sl={6} xs={12}>
                  <Box className={classes.boxconthoverleft}>
                    <Box className={classes.boxcontrolle}>
                      <Typography className={classes.controlle1}>{t('trans.calculatorOverviewOne')}</Typography>
                    </Box>
                    <Box className={classes.boxcontrolle1}>
                      <Typography className={classes.controlle2}>{employee}</Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={2} md={4} sm={4} sl={6} xs={12}>
                  <Box className={classes.boxconthover}>
                    <Box className={classes.boxcontrolle}>
                      <Typography className={classes.controlle1}>{t('trans.calculatorOverviewTwo')}</Typography>
                    </Box>
                    <Box className={classes.boxcontrolle1}>
                      <Typography className={classes.controlle2}>
                        {visitor} /{externalEmployees}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={2} md={4} sm={4} sl={6} xs={12}>
                  <Box className={classes.boxconthover}>
                    <Box className={classes.boxcontrolle}>
                      <Typography className={classes.controlle1}>{t('trans.calculatorOverviewThree')}</Typography>
                    </Box>
                    <Box className={classes.boxcontrolle1}>
                      <Typography className={classes.controlle2}>{instruction}</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={2} md={4} sm={4} sl={6} xs={12}>
                  <Box className={classes.boxconthover}>
                    <Box className={classes.boxcontrolle}>
                      <Typography className={classes.controlle1}>{t('trans.calculatorOverviewFour')}</Typography>
                    </Box>
                    <Box className={classes.boxcontrolle1}>
                      <Typography className={classes.controlle2}>{averageInstruction}</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={2} md={4} sm={4} sl={6} xs={12}>
                  <Box className={classes.boxconthover}>
                    <Box className={classes.boxcontrolle}>
                      <Typography className={classes.controlle1}>{t('trans.calculatorOverviewFive')}</Typography>
                    </Box>
                    <Box className={classes.boxcontrolle1}>
                      <Typography className={classes.controlle2}>{duration}</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={2} md={4} sm={4} sl={6} xs={12}>
                  <Box className={classes.boxconthoverright}>
                    <Box className={classes.boxcontrolle}>
                      <Typography className={classes.controlle1}>{t('trans.calculatorOverviewSix')}</Typography>
                    </Box>
                    <Box className={classes.boxcontrolle1}>
                      <Typography className={classes.controlle2}>{costsEuro}</Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Dialog
        onClose={handleClose}
        maxWidth={1000}
        aria-labelledby="customized-dialog-title"
        open={open}
        // classes={{ paper: classes.paper }}
      >
        <DialogTitle style={{ background: '#EBF0F4' }} id="customized-dialog-title" onClose={handleClose}>
          {t('trans.calculatorDialogHead')}
          <Box mt={0} className={classes.contentBoxInstruction}>
            <Typography className={classes.dialogcontentbold1}>{t('trans.calculatorDialopotential')}</Typography>
            <Box ml={0}>
              <Typography className={classes.dialogcontentgreen}>
                {differenzCostsEuro} {t('trans.calculatorDialogSavingsEuro')}
              </Typography>
              <Typography className={classes.dialogcontentgreen}>
                {procentCosts} %{t('trans.calculatorDialogSavingsProcent')}
              </Typography>
              <Typography className={classes.dialogcontenterrorMessage}>{errorMessage}</Typography>
            </Box>
          </Box>
        </DialogTitle>

        <DialogContent dividers>
          <Box mt={0} mb={2} className={classes.contentBoxInstruction}>
            <Typography className={classes.dialogcontent}>{t('trans.calculatorDialogDescriptionhead')}</Typography>
          </Box>
          <TableContainer>
            <Typography className={classes.dialogcontentboldhead}>{t('trans.calculatorDialogEmployeeUMO')}</Typography>
            <Table className={classes.customTable} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cell_longHeader}>{t('trans.calculatorDialogUserTable')}</TableCell>
                  <TableCell className={classes.cell_shortHeader}>{t('trans.calculatorDialogQuantity')}</TableCell>
                  <TableCell className={classes.cell_shortHeader}>{t('trans.calculatorDialogUnitPrice')}</TableCell>
                  <TableCell className={classes.cell_shortHeader}>{t('trans.calculatorDialogTotal')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.cell_long} component="th">
                    {t('trans.calculatorDialogUserOne')}
                    {t('trans.calculatorDialogUser')}
                  </TableCell>
                  <TableCell className={classes.cell_short}>{employee99}</TableCell>
                  <TableCell className={classes.cell_short}>{employeePrices99Euro}</TableCell>
                  <TableCell className={classes.cell_short}>{employeePrices99ResultEuro}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className={classes.cell_long} component="th">
                    {t('trans.calculatorDialogUserTwo')}
                    {t('trans.calculatorDialogUser')}
                  </TableCell>
                  <TableCell className={classes.cell_short}>{employee499}</TableCell>
                  <TableCell className={classes.cell_short}>{employeePrices499Euro}</TableCell>
                  <TableCell className={classes.cell_short}>{employeePrices499ResultEuro}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className={classes.cell_long} component="th">
                    {t('trans.calculatorDialogUserThree')}
                    {t('trans.calculatorDialogUser')}
                  </TableCell>
                  <TableCell className={classes.cell_short}>{employee500}</TableCell>
                  <TableCell className={classes.cell_short}>{employeePrices500Euro}</TableCell>
                  <TableCell className={classes.cell_short}>{employeePrices500ResultEuro}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ width: 400, fontWeight: 'bold' }} component="th" align="left">
                    {t('trans.calculatorDialogTotal')}
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right">
                    {employeeAll}
                  </TableCell>
                  <TableCell className={classes.cell_short} />
                  <TableCell style={{ fontWeight: 'bold' }} align="right">
                    {costAllEuro}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer>
            <Typography className={classes.dialogcontentboldhead}>
              {t('trans.calculatorDialogInstructionsUMO')}
            </Typography>
            <Table className={classes.customTable} size="small" aria-label="a dense table table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cell_longHeader}>
                    {t('trans.calculatorDialogInstructionTable')}
                  </TableCell>
                  <TableCell className={classes.cell_shortHeader}>{t('trans.calculatorDialogQuantity')}</TableCell>
                  <TableCell className={classes.cell_shortHeader}>{t('trans.calculatorDialogUnitPrice')}</TableCell>
                  <TableCell className={classes.cell_shortHeader}>{t('trans.calculatorDialogTotal')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.cell_long} component="th">
                    {t('trans.calculatorDialogInstructionOne')}
                    {t('trans.calculatorDialogInstruction')}
                  </TableCell>
                  <TableCell className={classes.cell_short}>{instruction5}</TableCell>
                  <TableCell className={classes.cell_short}>{instructionPrices5Euro}</TableCell>
                  <TableCell className={classes.cell_short}>{instructionPrices5ResultEuro}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className={classes.cell_long} component="th">
                    {t('trans.calculatorDialogInstructionTwo')}
                    {t('trans.calculatorDialogInstruction')}
                  </TableCell>
                  <TableCell className={classes.cell_short}>{instruction25}</TableCell>
                  <TableCell className={classes.cell_short}>{instructionPrices25Euro}</TableCell>
                  <TableCell className={classes.cell_short}>{instructionPrices25ResultEuro}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className={classes.cell_long} component="th">
                    {t('trans.calculatorDialogInstructionThree')}
                    {t('trans.calculatorDialogInstruction')}
                  </TableCell>
                  <TableCell className={classes.cell_short}>{instruction26}</TableCell>
                  <TableCell className={classes.cell_short}>{instructionPrices26Euro}</TableCell>
                  <TableCell className={classes.cell_short}>{instructionPrices26ResultEuro}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ width: 400, fontWeight: 'bold' }} component="th" align="left">
                    {t('trans.calculatorDialogTotal')}
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right">
                    {instructionAll}
                  </TableCell>
                  <TableCell align="right" />
                  <TableCell style={{ fontWeight: 'bold' }} align="right">
                    {instructioncostAllEuro}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer>
            <Box mt={0} mb={2} className={classes.contentBoxInstruction}>
              <Typography className={classes.dialogcontent}>{t('trans.calculatorDialogComparison')}</Typography>
            </Box>
            <Typography className={classes.dialogcontentboldhead}>
              {t('trans.calculatorDialogFaceToFaceInstruction')}
            </Typography>
            <Table className={classes.customTable} size="small" aria-label="a dense table table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cell_longHeaderFive}>
                    {t('trans.calculatorDialogDesignation')}
                  </TableCell>
                  <TableCell className={classes.cell_shortHeaderFive}>{t('trans.calculatorDialogQuantity')}</TableCell>
                  <TableCell className={classes.cell_shortHeaderFive}>
                    {t('trans.calculatorDialogInstructions')}
                  </TableCell>

                  <TableCell className={classes.cell_shortHeaderFive}>{t('trans.calculatorDialogDuration')}</TableCell>
                  <TableCell className={classes.cell_shortHeaderFive}>
                    {t('trans.calculatorDialogHourlyWage')}
                  </TableCell>
                  <TableCell className={classes.cell_shortHeaderFive}>{t('trans.calculatorDialogTotal')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.cell_longFive} component="th">
                    {t('trans.calculatorDialogEmployee')}
                  </TableCell>
                  <TableCell className={classes.cell_short}>{employee}</TableCell>
                  <TableCell className={classes.cell_short}>{averageInstruction}</TableCell>

                  <TableCell className={classes.cell_short}>{duration}</TableCell>
                  <TableCell className={classes.cell_short}>{costsEuro}</TableCell>
                  <TableCell className={classes.cell_short}>{instructionCostEuro}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className={classes.cell_longFive} component="th">
                    {t('trans.calculatorDialogVisitor')}
                  </TableCell>
                  <TableCell className={classes.cell_short}>{visitor}</TableCell>
                  <TableCell className={classes.cell_short}>{visitor}</TableCell>

                  <TableCell className={classes.cell_short}>{duration}</TableCell>
                  <TableCell className={classes.cell_short}>{costsEuro}</TableCell>
                  <TableCell className={classes.cell_short}>{gesamtinstructionCostVisitorEuro}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className={classes.cell_longFive} component="th">
                    {t('trans.calculatorDialogForeignWorkers')}
                  </TableCell>
                  <TableCell className={classes.cell_short}>{externalEmployees}</TableCell>
                  <TableCell className={classes.cell_short}>{externalEmployees}</TableCell>

                  <TableCell className={classes.cell_short}>{duration}</TableCell>
                  <TableCell className={classes.cell_short}>{costsEuro}</TableCell>
                  <TableCell className={classes.cell_short}>{gesamtinstructionCostExternalEmployeesEuro}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ width: 250, fontWeight: 'bold' }} component="th" align="left">
                    {t('trans.calculatorDialogTotal')}
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right" />
                  <TableCell align="right" />
                  <TableCell align="right" />
                  <TableCell style={{ maxWidth: 100 }} align="right" />
                  <TableCell style={{ fontWeight: 'bold', maxWidth: 100 }} align="right">
                    {gesamtCostsEuro}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Box mt={0} mb={1} className={classes.contentBoxInstruction}>
            <Typography className={classes.dialogcontent}>{t('trans.calculatorDialogInfoFooterOne')}</Typography>
          </Box>
          <Box mt={0} mb={2} className={classes.contentBoxInstruction}>
            <Typography className={classes.dialogcontent}>{t('trans.calculatorDialogInfoFooterTwo')}</Typography>
          </Box>

          <Box mt={1} mb={2} className={classes.contentBoxInstruction}>
            <Typography className={classes.dialogcontent}>
              {umoGesamtEuro} {t('trans.calculatorDialogResultOne')}
            </Typography>
            <Typography className={classes.dialogcontent}>
              {instructionCostUMOEuro} {t('trans.calculatorDialogResultTwo')}
            </Typography>

            <Typography className={classes.dialogcontentergebnisbold}>
              {newCostInstructionEuro} {t('trans.calculatorDialogResultThree')}
            </Typography>
          </Box>
          <Box mt={0} mb={1} className={classes.contentBoxInstruction}>
            <Typography className={classes.dialogcontent}>
              {t('trans.calculatorDialogInfoOne')} <b>{newCostInstructionEuro}</b> {t('trans.calculatorDialogInfoTwo')}{' '}
              <b>{gesamtCostsEuro}</b> {t('trans.calculatorDialogInfoThree')}
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography className={classes.dialogcontent}>
              {t('trans.contentContainerPricesContent_4')}{' '}
              <LinkText to="/contact/" title="Kontaktformular">
                {t('trans.contentContainerPricesContent_4Link')}
              </LinkText>{' '}
              {t('trans.contentContainerPricesContent_5')}
            </Typography>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            {t('trans.contentContainerPricesClose')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PricesCalculator;
// const [employee, setemployee] = React.useState(50);
// const [visitor, setvisitor] = React.useState(0);
// const [externalEmployees, setexternalEmployees] = React.useState(0);
// const [instruction, setinstruction] = React.useState(15);
// const [averageInstruction, setaverageInstruction] = React.useState(12);
// const [duration, setduration] = React.useState(30);
// const [costs, setcosts] = React.useState(30);
